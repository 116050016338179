.title-container {
    margin-left: 253px;
    margin-right: 253px;
    font-weight: 400;
    font-size: 16px;
    color: #4b4444;
    position: static;
    display: flex;
    padding-bottom: 20px;
}

.title {
    position: static;
    color: #1c1c1c;
    font-size: 27px;
    margin-top: 15px;
}

.navlink {
    padding: 34px 17px;
    position: relative;
    display: inline-block;
}

.navlink-container {
    right: 253px;
    position: absolute;
}
