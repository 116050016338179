.projects-alignment {
    border: 1px solid #ddd;
    background-color: #ddd;
}

.projects {
    padding: 1%;
    color: #135a98;
    border-bottom: 1px solid #135a98;
}

.projects-content {
    margin-left: 253px;
    margin-right: 253px;
}

.aligh-text-center {
    text-align: center;
}

.projects-header {
    font-size: larger;
    font-family: system-ui;
    font-weight: 400;
}

.project-logos {
    display: flex;
    text-align: center;
    padding: 1%;
}

.project-image {
    width: 80%;
}


.projects-title {
    color: #135a98;
}