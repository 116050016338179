.banner-alignment {
    /* margin-top: 34px; */
    /* padding: 40px 0px; */
    border: 1px solid #ddd;
    background-color: #135a98;
    /* background-color: #4dbd90; */
    /* background-image: url('../../assets/background-layout-1.jpg'); */
}

.banner-content {
    margin-left: 253px;
    margin-right: 253px;
    padding: 30px 0px;
    display: flex;
    /* background-image: url('../../assets/background-layout.jpg'); */
    background-size: 100%;
    background-repeat: no-repeat;
    height: 350px;
}

.main-heading {
    margin-bottom: 20px;
    font-size: 38px;
    /* color: white; */
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
}

.banner-image {
    width: 85%;
    text-align: center;
    height: 350px;
    /* background-image:
        linear-gradient(to bottom, rgba(134, 133, 133, 0.301), #4dbd90),
        url('../../assets/house-banner.jpg');

    background-repeat: no-repeat;
    background-size: cover; */
}

.sub-title {
    font-size: 20px;
    font-weight: 300;
    /* color: white; */
    padding-left: 10px;
    padding-right: 10px;
}

.actions {
    padding-top: 10px;
}

.banner-action-button-primary {
    padding: 13px 23px;
    background-color: black;
    color: white;
}

.banner-action-button-secondary {
    padding: 13px 23px;
    background-color: white;
    color: black;
    margin-left: 10px;
}

/* .banner-image:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    height: 40%;
} */


.content {
    margin-left: 253px;
    margin-right: 253px;
    display: flex;
}

.left-pane-content {
    background-color: #135a98;
    color: white;
    padding-left: 10% !important;
    padding-top: 3%;
}

.content-header {
    font-weight: 600;
    font-size: x-large;
    font-family: system-ui;
}

.content-subheader {
    color: #5197c7;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    padding-top: 1%;
    padding-bottom: 3%;
}

.right-pane-content {
    background-color: #135a98;
    color: white;
    padding-left: 2%;
    padding-top: 3%;
}

.aligh-text-center {
    text-align: center;
}

.align-image {
    padding: 1%;
}

.align-subtitle {
    margin-top: 6%;
}