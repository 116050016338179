.whatwedo-alignment {
    margin-top: 2%;
}

.whatwedo {
    padding: 1%;
    color: #135a98;
    border-bottom: 1px solid #135a98;
}

.whatwedo-content {
    margin-left: 253px;
    margin-right: 253px;
}

.aligh-text-center {
    text-align: center;
}

.whatwedo-header {
    font-weight: 600;
    font-size: larger;
    font-family: system-ui;
    padding-top: 3%;
}

.whatwedo-subheader {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 1% 0%;
    font-size: larger;
}

.whatwedo-logos {
    display: flex;
    text-align: center;
    padding: 1%;
}

.whatwedo-image {
    width: 80%;
}

.whatwedo-flex-content {
    display: flex;
    margin-top: 4%;
}

img {
    /*  To contain the image to the confines of the div  */
    max-width: 80%;
}

.img-gradient {
    max-width: 80%;
    margin: auto;
}

.align-image {
    width: 100%;
    height: 40vh;
}

.img-gradient::after {
    display: block;
    position: relative;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 100%);
    margin-top: -150px;
    height: 150px;
    width: 100%;
    content: '';
}

.align-description {
    display: flex;
    align-items: center;
}

.font-large-icon {
    font-size: xxx-large !important;
}

.justify-content {
    text-align: justify;
}