.description-cell {
    font-weight: 600;
}

.header-cell {
    background-color: #135a98 !important;
    color: white !important;
    text-align: center;
}

#pricing-table > tbody > tr > td {
    background-color: #e3ecf1;
}